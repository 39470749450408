import { UploadOutlined } from '@ant-design/icons';
import { Button, Input, Form, message, Upload, Row, Col, Card } from 'antd';
import axios from 'axios';
import { useFormik } from 'formik';
import { default as cookies } from 'js-cookie';
import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import loaderImg from '../../../assets/Loaders.json';
import Component from 'constants/Component';
import Icons from 'constants/Icons';
import img from 'constants/Img';
import { SharedData } from 'context/CallApi';
import { LocalizationContext } from 'context/LangChange';

const EditAccount = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  let navigate = useNavigate();
  let { isLang } = useContext(LocalizationContext);
  let { fetchPersonalData, fetchPersonal } = useContext(SharedData);

  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
      email: '',
      image: selectedImage,
    },
    onSubmit: async (values) => {
      try {
        const url = `https://holmz.rightclick.com.sa/api/auth/update-personal-data`;
        let { data } = await axios.post(url, values, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Accept-Language': cookies.get('i18next'),
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        });
        message.success(data?.message);
        fetchPersonal();
        setTimeout(() => {
          navigate('/account/personal_data');
        }, 1500);
      } catch (error) {
        message.error('Error updating data.');
      }
    },
  });

  useEffect(() => {
    formik.setValues({
      name: fetchPersonalData?.name || '',
      email: fetchPersonalData.email || '',
      phone: fetchPersonalData.phone || '',
      image: fetchPersonalData.image || '',
    });
  }, [fetchPersonalData, formik.setValues]);

  const handleImageSelect = (info) => {
    if (info.file.status === 'uploading') {
      setIsLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      setSelectedImage(info.file.originFileObj);
      formik.setFieldValue('image', info.file.originFileObj);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="app__PersonalData" style={{ maxWidth: '900px', margin: 'auto' }}>
      <Card className='shadow-3'>
        <Form onFinish={formik.handleSubmit} layout="vertical">
          <Row gutter={[16, 16]}>
            <Col span={24} style={{ textAlign: 'center' }}>
              <Upload
                name="image"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                customRequest={({ file, onSuccess }) => {
                  setTimeout(() => {
                    onSuccess('ok');
                    handleImageSelect({ file, status: 'done' });
                  }, 1000);
                }}
                onChange={handleImageSelect}
              >
                {isLoading ? (
                  <img src={loaderImg} alt="Loading" style={{ width: '100%' }} />
                ) : selectedImage ? (
                  <img src={URL.createObjectURL(selectedImage)} alt="Profile" style={{ width: '100%' }} />
                ) : (
                  formik.values.image ? (
                    <img src={formik.values.image} alt="Profile" style={{ width: '100%' }} />
                  ) : (
                    <img src={img.personal} alt="Profile" style={{ width: '100%' }} />
                  )
                )}
              </Upload>
            </Col>

            <Col span={24}>
              <Form.Item label={isLang === 'en' ? 'First Name' : 'الاسم الأول'}>
                <Input
                  id="name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder={isLang === 'en' ? 'Enter your name' : 'أدخل اسمك'}
                />
                {formik.errors.name && formik.touched.name && (
                  <div className="ant-form-item-explain-error">{formik.errors.name}</div>
                )}
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item label={isLang === 'en' ? 'Phone Number' : 'رقم الجوال'}>
                <Input
                  id="phone"
                  name="phone"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder={isLang === 'en' ? 'Enter your phone number' : 'أدخل رقم الجوال'}
                />
                {formik.errors.phone && formik.touched.phone && (
                  <div className="ant-form-item-explain-error">{formik.errors.phone}</div>
                )}
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item label={isLang === 'en' ? 'Email' : 'البريد الإلكتروني'}>
                <Input
                  id="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder={isLang === 'en' ? 'Enter your email' : 'أدخل البريد الإلكتروني'}
                />
                {formik.errors.email && formik.touched.email && (
                  <div className="ant-form-item-explain-error">{formik.errors.email}</div>
                )}
              </Form.Item>
            </Col>

            <Col span={24}>
              <Button type="primary" htmlType="submit" block>
                {isLang === 'en' ? 'Save changes' : 'حفظ التغيرات'}
              </Button>
            </Col>
          </Row>
        </Form>
        <Component.ChangePasword isLang={isLang} />
      </Card>
    </div>
  );
};

export default EditAccount;
