import { ConfigProvider, FloatButton } from 'antd';
import General from 'context/General';
import { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import Component from './constants/Component';
import CallApi from './context/CallApi';
import LangChange from './context/LangChange';
import './style/App.scss';
import Icons from 'constants/Icons';

function App() {
  function ProtectedRoutes({ children }) {
    let token = localStorage.getItem('token')
    if (!token) {
      return <Navigate to="/holmes/sign-in" replace={true} />
    } else {
      return children;
    }
  }


  useEffect(() => {
    if (!localStorage.getItem('token')) {
      let guest_id = Math.floor(Math.random() * Math.floor(Math.random() * Date.now()))
      localStorage.setItem('guest_id', guest_id)
    }
  }, [])


  const root = createBrowserRouter([
    {
      path: `/`, element: <Component.Holmes />, children: [
        { index: true, element: <Component.Home /> },
        { path: '/about', element: <Component.About /> },
        { path: '/order', element: <Component.OrderStatus /> },
        { path: '/checkout', element: <ProtectedRoutes><Component.Checkout /></ProtectedRoutes> },
        { path: '/orders/:id', element: <ProtectedRoutes><Component.MyOrdersDetails /> </ProtectedRoutes> },

        {
          path: '/products', children: [
            { index: true, element: <Component.Products /> },
            { path: ':id', element: <Component.ProdactDetails /> }
          ]
        },

        { path: '/agent', element: <Component.Agent /> },
        { path: '/contact-us', element: <Component.Contact /> },
        { path: '/cart', element: <Component.Cards /> },
        { path: '/wishlist', element: <ProtectedRoutes> <Component.Favorite /></ProtectedRoutes> },
        { path: '*', element: <h1>Page Notfound</h1> },
      ],
    },

    {
      path: '/holmes/', element: <Component.Auth />, children: [
        { path: 'sign-in', element: <Component.Login /> },
        { path: 'sign-up', element: <Component.Register /> },
        { path: 'otp', element: <Component.OTP /> },
        { path: 'reset-password', element: <Component.ResetPassword /> },
      ]
    },

    {
      path: '/account/', element: <ProtectedRoutes><Component.Account /></ProtectedRoutes>, children: [
        { path: 'personal_data', element: <ProtectedRoutes><Component.PersonalData /> </ProtectedRoutes> },
        { path: 'edit', element: <ProtectedRoutes><Component.EditAccount /> </ProtectedRoutes> },
        // { path: 'cart', element: <ProtectedRoutes><Component.CartPersonal /> </ProtectedRoutes> },
        { path: 'orders', element: <ProtectedRoutes><Component.MyOrders /> </ProtectedRoutes> },

        { path: 'favorites', element: <ProtectedRoutes><Component.FavoritesPersonal /> </ProtectedRoutes> },

      ]
    },
  ])
  const theme = {
    token: {
      colorPrimary: '#FFCC48',
      colorActiveText: 'red'
    },
    components: {
      Button: { colorPrimary: '#000' },
      BackTop: { colorPrimary: '#000' },
      Input: { colorPrimary: '#FFCC48' },
      Tabs: { colorPrimary: '#000' },
      Steps: { colorPrimary: '#3baf08' },
      Checkbox: { colorPrimary: '#FFCC48' },
      Dropdown: { colorPrimary: '#FFCC48' },
      Carousel: { colorPrimary: '#FFCC48' },


    },
  };
  return (
    <div>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          duration: 3500,
          style: {
            fontFamily: ' Arial, Helvetica, sans-serif',
            textTransform: 'capitalize',
            zIndex: '9999',
            // background: '#000',
            // color: '#fff',
            borderRadius: '10px',
            boxShadow: '10px 10px 10px rgba(188, 188, 188, 0.16)',
            background: '#fff',
            color: '#000',
          },
        }}
        containerStyle={{
          bottom: 50
        }}
      />
      <FloatButton.BackTop type='primary' duration={2000}  >
        <div className="back-to-top">
          <Icons.arrowTop />
        </div>
      </FloatButton.BackTop>
      <ConfigProvider theme={theme} >
        <LangChange>
          <General>
            <CallApi>
              <RouterProvider router={root} />
            </CallApi>
          </General>
        </LangChange>
      </ConfigProvider>
    </div>
  );
}

export default App;
