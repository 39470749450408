import axios from 'axios';
import Cookies, { default as cookies } from 'js-cookie';
import { createContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

export const LocalizationContext = createContext([])

function LangChange({ children }) {

  const [isLang, setIsLang] = useState(Cookies.get('i18next'));
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(true);
 

  useEffect(() => {
    Cookies.set('i18next', isLang)
    setTimeout(() => {
      setLoading(false)
    }, 2000);
  }, [isLang])

  async function LogOut() {
    let { data } = await axios.post(`https://holmz.rightclick.com.sa/api/auth/logout`, {}, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Accept-Language': cookies.get('i18next'),
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }
    })

    if (data?.status === 200) {
      toast.success(data?.message)
    }
    localStorage.removeItem("token"); 
    window.location.reload()
  }
  return (
    <LocalizationContext.Provider value={{ isOpen, setIsOpen, LogOut, loading, setLoading, isLang, setIsLang }}>
      {children}
    </LocalizationContext.Provider>
  )
}

export default LangChange