import React, { useCallback } from 'react';
import useProdactDetails from './useProdactDetails';
import { Button, InputNumber, Row, Col, Carousel, Rate, Card, Tag } from 'antd';
import { HeartOutlined, HeartFilled, PlusOutlined, MinusOutlined, TagsOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './details.scss';

const ProdactDetails = () => {
  const { t } = useTranslation();
  let { id } = useParams();
  const {
    isFavouriteActive,
    setIsFavouriteActive,
    price,
    setPrice,
    sizeActive,
    setSizeActive,
    Index,
    setIndex,
    images,
    data,
    value2,
    setValue2,
    toggleFavourite,
    addCart,
  } = useProdactDetails(id);

  const changePrice = useCallback((item) => {
    setSizeActive(item.id);
    setPrice(item.price);
  }, [sizeActive, price]);

  return (
    <div className="app__Product_details mb-8">
      <div className="slider_hover">
        <div className="prodact_details_container">
          <Row className="card__image mb-5">
            {images && (
              <>
                <Col xl={4} lg={4} className="small-images-container h-100 card__image_lg">
                  {images?.slice(0, 3).map((item, i) => (
                    item ? (
                      <img
                        className={i === Index ? 'small-image selected-image p-2' : 'small-image'}
                        key={i}
                        src={item}
                        onMouseEnter={() => setIndex(i)}
                        style={{ objectFit: 'contain', objectPosition: '50% 50%', width: '141px', height: '141px' }}
                        alt="image"
                      />
                    ) : null
                  ))}
                </Col>
                <Col xl={10} lg={10} className="product-detail-image shadow-1 p-5 card__image_lg">
                  {images?.length === 1 ? (
                    <img
                      src={images[0]}
                      alt="product detail"
                      style={{ borderRadius: '15px', objectFit: 'contain', objectPosition: '50% 50%' }}
                      className="w-100 product-detail-images"
                    />
                  ) : (
                    <img
                      src={images && images[Index]}
                      alt="product detail"
                      style={{ borderRadius: '15px', objectFit: 'contain', objectPosition: '50% 50%' }}
                      className="w-100 product-detail-images"
                    />
                  )}
                </Col>
                <div className="card__image_sm">
                  <Carousel autoplay infinite={true} speed={2000} swipeToSlide={true}>
                    {images?.map((item) => (
                      <div className="slide-container flex justify-content-center align-items-center">
                        <img src={item} alt="" className="cart_images" />
                      </div>
                    ))}
                  </Carousel>
                </div>

                <Col xl={10} lg={10}>
                  <div className="view_details mt-8">
                    <div className="item_details">
                      <div className="flex justify-content-between align-items-center">
                        <div className="header">
                          <span className="details_span">product name</span>
                          <h3>{data.name}</h3>
                        </div>
                        <div className="favourite">
                          <Button
                            className="favourite-btn"
                            type="default"
                            icon={data?.in_favourite === 1 ? <HeartFilled /> : <HeartOutlined />}
                            shape="circle"
                            size="large"
                            onClick={() => toggleFavourite(id, data?.in_favourite)}
                          />
                        </div>
                      </div>

                      <div className="rate flex gap-2 align-items-center">
                        <span className="rate_title">{5}</span>
                        <Rate disabled value={5} />
                      </div>
                    </div>
                    <div className="dec mt-4">
                      <span className="details_span">about project</span>
                      <p className="m-0 p-0">{data?.shot_description}</p>
                    </div>


                    <div className="size-title">
                      <span className="mb-3 details_span">Choose Size</span>
                      <div className="size-container">
                        {data?.sizes?.map((item, index) => (
                          <div
                            key={index}
                            className={`size-box ${Number(sizeActive) === Number(item.id) ? 'size-box-active' : ''}`}
                            onClick={() => changePrice(item)}
                          >
                            <span className="size-box-label">{item?.price?.toLocaleString()} SAR</span>
                            <span className="size-box-price">{item?.name} mg</span>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="btn_details_content">
                      <div className="siz_btn">
                        <span className="details_span">quantity</span>
                  
                        <div className="flex-auto mt-5">
                          <InputNumber
                            value={value2}
                            onChange={(value) => setValue2(value)}  // تأكد من تحديث القيمة هنا
                            min={1}  // أقل قيمة للكمية
                            step={1}  // الخطوة الواحدة لكل زيادة أو تقليل
                            addonAfter={<Button type='text' icon={<PlusOutlined />} onClick={() => setValue2(value2 + 1)} />}  // زر لزيادة الكمية
                            addonBefore={<Button type='text' icon={<MinusOutlined />} onClick={() => setValue2(Math.max(1, value2 - 1))} />}  // زر لتقليل الكمية مع التأكد أن القيمة لا تقل عن 1
                          />
                        </div>

                      </div>
                      <div className="total">
                        <span className='details_span'>Total</span>
                        <h3 className="mt-5">
                          {(price * value2)?.toLocaleString()} <sub>SAR</sub>
                        </h3>
                      </div>
                    </div>

                    <div className="checkout_btn mt-8 w-100 flex flex-column gap-4 justify-content-center">
                 
                      <div className="checkout_btn_cart">
                        <Button
                         type="primary"  
                          icon={<TagsOutlined />}
                          size="large"
                          className="w-100"
                          onClick={() => addCart(id)}
                        >
                          Add to cart
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              </>
            )}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default ProdactDetails;
