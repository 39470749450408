import { Col, Row, theme } from 'antd';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { GeneralData } from 'context/General';
import MySidebar from 'Pages/Admin/MyOrders/MySidebar';
import { Outlet } from 'react-router-dom';
import Component from 'constants/Component';

const Account = () => {
    const { i18n } = useTranslation();
    return (
        <div>
            <Component.NavBar />
            <div className='app_orders_page'>
                <Row className='w-100'>
                    <Col xl={4} lg={4} md={4}  >
                        <MySidebar />
                    </Col>
                    <Col xl={20} lg={20} md={20}  >
                        <Outlet></Outlet>
                    </Col>
                </Row>
            </div>
            <Component.Footer />
        </div>
    )
}

export default Account
