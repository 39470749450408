import { HeartOutlined, LoginOutlined, LogoutOutlined, MenuFoldOutlined, ShoppingCartOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Drawer, Menu } from 'antd';
import img from 'constants/Img';
import { SharedData } from 'context/CallApi';
import { LocalizationContext } from 'context/LangChange';
import i18next from 'i18next';
import cookies from 'js-cookie';
 import { useContext, useEffect, useState } from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import LogoNav from './LogoNav';
import NavDrop from './NavDrop';
import NavLinks from './NavLinks';
import NavNumber from './NavNumber';
import './navbar.scss';
import routes from './routes';
 
const languages = [
    {
        code: 'en',
        name: 'English',
        dir: 'ltr',
        country_code: 'gb',
    },
    {
        code: 'ar',
        name: 'العربية',
        dir: 'rtl',
        country_code: 'sa',
    },
    {
        code: 'fr',
        name: 'Français',
        dir: 'ltr',
        country_code: 'fr',
    },
];

const NavBar = ({ navbarColor }) => {
    const { t } = useTranslation();
    let { favourite, cart, fetchPersonalData } = useContext(SharedData);
    let { isLang, setIsLang } = useContext(LocalizationContext);
    let navigate = useNavigate();

    const [showMenu, setShowMenu] = useState(false);
    const [activeLink, setActiveLink] = useState(0);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [visibleRight, setVisibleRight] = useState(false);

    const location = useLocation();

    const currLangCode = cookies.get('i18next') || 'en';
    const currLang = languages.find((l) => l.code === currLangCode);

    useEffect(() => {
        document.body.dir = currLang.dir || 'ltr';
    }, [currLang, t]);

    const handleLanguageChange = (lang) => {
        setIsLang(lang);
        const selectedLang = languages.find((l) => l.code === lang);
        document.body.dir = selectedLang.dir;
        i18next.changeLanguage(lang);
    };

    const onAvatarClick = (event) => setShowMenu(!showMenu);

    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(window.scrollY);
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const [open, setOpen] = useState(false);
    const [placement, setPlacement] = useState('left');
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    const handleLinkClick = (index) => {
        setActiveLink(index);
        setOpen(true);
    };
    let { LogOut } = useContext(LocalizationContext);

    const token = localStorage.getItem('token'); // Assuming token is stored in cookies

    const additionalItems = [];

    if (token) {
        // User is logged in
        additionalItems.push(
            {
                key: 'personal_data',
                icon: <UserOutlined />,
                label: (
                    <Link to="/account/personal_data">
                        {isLang === 'en' ? 'Profile' : 'الملف الشخصي'}
                    </Link>
                )
            },
            {
                key: 'cart',
                icon: <ShoppingCartOutlined />,
                label: (
                    <Link to="/cart">
                        {`Cart (${cart?.length})`}
                    </Link>
                )
            },
            {
                key: 'wishlist',
                icon: <HeartOutlined />,
                label: (
                    <Link to="/wishlist">
                        {`Wishlist (${favourite.length > 0 ? favourite.length : 0})`}
                    </Link>
                )
            },
            {
                key: 'Logout',
                icon: <LogoutOutlined />,
                label: (<span onClick={() => {
                    LogOut()
                    navigate('/');  
                }}>{isLang === 'en' ? 'Logout' : 'تسجيل خروج'}</span>)
            }
        );
    } else {
        // No token, show login/register
        additionalItems.push(
            {
                key: 'Register',
                icon: <LoginOutlined />,
                label: (
                    <Link to="/holmes/sign-up">
                        Register
                    </Link>
                )
            },
            {
                key: 'login',
                icon: <LoginOutlined />,
                label: (
                    <Link to="/holmes/sign-in">
                        Login
                    </Link>
                )
            });
    }
    return (
        <div className='app__navbar'>
            <Navbar
                dir='ltr'
                fixed={scrollPosition > 600 ? 'top' : 'top'}
                collapseOnSelect
                expand="sm"
                className={`${navbarColor} ${scrollPosition > 600 ? 'scroll navbar-navOnthorPAge shadow-sm' : ''} shadow`}
            >
                <div className="flex justify-content-end gap-6 align-items-center w-100 container-fluid"
                    dir={currLang.dir !== 'ltr' ? 'ltr' : 'rtl'}
                >
                    <div className="nav_logo_sm">
                        <div className="nav_links flex justify-content-between nav_bar_am">
                            <div className="sidebar_sm">
                                <Nav className='w-50'>
                                    {/* Avatar + profile */}
                                    <NavDrop handleAvatarClick={onAvatarClick} setShowMenu={setShowMenu} token={localStorage.getItem('token') ? true : false} currLangCode={currLangCode} isLang={isLang} t={t} fetchPersonalData={fetchPersonalData} onAvatarClick={onAvatarClick} handleLanguageChange={handleLanguageChange} languages={languages} />
                                    {/* Cart + favourite */}
                                    <NavNumber favourite={favourite} cart={cart} scrollPosition={scrollPosition} />
                                </Nav>
                                {/* Link pages */}
                                <NavLinks cookies={cookies} t={t} location={location} />
                            </div>
                        </div>
                        <LogoNav scroll={scrollPosition} />
                    </div>
                    <div className="nav_logo_sidebar flex justify-content-between align-items-center">
                        <div className="sidebar_lg"> 
                            <Button   icon={<MenuFoldOutlined />} type='primary'  onClick={showDrawer}/>
                        </div>
                        <img src={img.Logo} alt="" className='rotateLogo' />
                    </div>

                    <Drawer
                        title={<span className='text-light'>Holmes</span>}
                        placement={cookies.get('i18next') !== "en" ? "right" : "left"}
                        closable={true}
                        onClose={onClose}
                        open={open}
                        key={placement}
                        className='bg_sidebar view_sidebar_home-page'
                        closeIcon={true}
                        zIndex={999}
                    >
                        <div className="navbar_sm_page">
                            <div className="d-flex flex-column" >
                                <section className={'routes'} >

                                    <Menu
                                        theme="dark"
                                        mode="inline"
                                        defaultSelectedKeys={[String(activeLink)]}
                                        items={[

                                            ...additionalItems,
                                            ...routes.map((route, index) => ({
                                                key: String(index),
                                                icon: route.icon, // Ensure that each route has a corresponding icon key
                                                label: (
                                                    <Link
                                                        className='mt-5'
                                                        to={route.path}
                                                        onClick={() => {
                                                            handleLinkClick(index);
                                                        }}
                                                    >
                                                        {isLang === "en" ? route.nameEn : route.nameAr}

                                                    </Link>
                                                )
                                            }))
                                        ]}
                                    />

                                </section>
                            </div>

                        </div>
                    </Drawer>

                </div>
            </Navbar>
        </div>
    );
};

export default NavBar;
