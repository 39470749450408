import AOS from 'aos'
import Icons from 'constants/Icons'
import img from 'constants/Img'
import { SharedData } from 'context/CallApi'
import { useContext, useEffect } from 'react'
import { Row, Col } from 'antd' // Replacing with ant-design
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import './footer.scss'

export const Footer = () => {
    let { t } = useTranslation()
    let { fetchSettings } = useContext(SharedData)

    useEffect(() => {
        AOS.init({
            once: true,
        });
        AOS.refresh();
    }, [])

    return (
        <div className="footer-top">
            <div className='app__Footer'>
                <div className='container'>
                    <Row justify="space-between" gutter={[16, 16]}>
                        <Col xl={5} lg={5} md={12} sm={24} className='content1 mt-3'>
                            <div>
                                <img src={img.Logo} className='mb-4 z-5' alt="logo right click" />
                                <br />
                                <span className='app__footer-p'>{fetchSettings?.description}</span>
                            </div>
                        </Col> 
                        <Col xl={7} lg={7} md={12} sm={24} className='content2 mt-3'>
                            <div>
                                <h1>{t("footer_quick_links")}</h1>
                                <div className="footer-ui">
                                    <ul>
                                        <Link to={'/'}> <li>{t("nav_home")}</li></Link>
                                        <Link to={'/about'}> <li>{t("nav_about")}</li></Link>
                                        <Link to={'/products'}> <li>{t("nav_product")}</li></Link>
                                        <Link to={'/contact-us'}> <li>{t("nav_contact")}</li></Link>
                                        <Link to={'/agent'}> <li>{t("nav_agent")}</li></Link>
                                    </ul>
                                </div>
                            </div>
                        </Col> 
                        <Col xl={8} lg={8} md={12} sm={24} className='content4 mt-3'>
                            <div>
                                <h1>{t("footer_contact")}</h1>

                                <div className="app__footer-contact-us">
                                    <a className='content-Contact-Us'
                                        href="https://www.google.com/maps/place/24%C2%B044'20.8%22N+46%C2%B038'48.4%22E/@24.7390256,46.6458355,18.85z/data=!4m4!3m3!8m2!3d24.739117!4d46.646789?entry=ttu"
                                        target="_blank" rel="noreferrer">
                                        <div
                                            className="phoneIcons phoneIcons-location round d-flex justify-content-center align-items-center "
                                            whileHover={{ rotate: 360 }}
                                            transition={{ duration: 0.3 }}
                                        >
                                            <Icons.location />
                                        </div>
                                        <p className="m-0 p-0">
                                            address
                                        </p>
                                    </a>
                                    <a href={`tel:+${fetchSettings?.phone}`} target='_blank' rel="noreferrer" className='m-0 p-0 content-Contact-Us'>
                                        <div
                                            className="phoneIcons round d-flex justify-content-center align-items-center "
                                            whileHover={{ rotate: 360 }}
                                            transition={{ duration: 0.3 }}
                                        >
                                            <Icons.CallOutlite />
                                        </div>
                                        <span dir='ltr'> {fetchSettings?.phone?.replace(/\D/g, '')?.replace(/(\d{3})(\d{2})(\d{3})(\d{4})/, '$1-$2-$3-$4')}</span>
                                    </a>
                                    <a href={`https://wa.me/+${fetchSettings?.whats}`} target='_blank' rel="noreferrer" className='m-0 p-0  content-Contact-Us'>
                                        <div
                                            className="phoneIcons round d-flex justify-content-center align-items-center "
                                            whileHover={{ rotate: 360 }}
                                            transition={{ duration: 0.3 }}
                                        >
                                            <Icons.Whatsapp />
                                        </div>
                                        <span dir='ltr'>{fetchSettings?.whats?.replace(/(\d{3})(\d{2})(\d{3})(\d{4})/, '$1-$2-$3-$4')}</span>

                                    </a>
                                    <a href={`mailto:${fetchSettings?.email}`} target='_blank' rel="noreferrer" className='m-0 p-0  content-Contact-Us'>
                                        <div
                                            className="phoneIcons round d-flex justify-content-center align-items-center "
                                            whileHover={{ rotate: 360 }}
                                            transition={{ duration: 0.3 }}
                                        >
                                            <Icons.Email />
                                        </div>
                                        <span dir='ltr'>{fetchSettings?.email}</span>
                                    </a>

                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="overlayer"></div>

                <div className="footer__copyright">
                    <p className='text-dark'>  &copy; 2024  <a href="https://rightclick.sa/" target='_blank' className='text-dark text-decoration-underline'>right click</a> </p>
                </div>
            </div> 
        </div>
    )
}
