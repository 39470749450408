import { DeleteOutlined, LoadingOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Image, Input, InputNumber, message, Rate, Row, Select, Spin } from 'antd';
import axios from 'axios';
import Icons from 'constants/Icons';
import { SharedData } from 'context/CallApi';
import cookies from 'js-cookie';
import { useContext, useState } from 'react';
import './checkout.scss';
import { useNavigate } from 'react-router-dom';

const { Option } = Select;

export const Checkout = () => {
    const [form] = Form.useForm()
    const { cart, fetchCart } = useContext(SharedData);
    const [loadingDelete, setDelete] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingId, setLoadingId] = useState(null);
    const navigate = useNavigate();


    const updateQuantity = (value, item) => {
        setLoadingId(item?.id)
        setLoading(true)
        let quantity = item?.quantity + value;
        update(item?.id, item?.size_id, quantity);
    }
    const update = async (id, size_id, quantity) => {
        let { data } = await axios.post(`https://holmz.rightclick.com.sa/api/cart/${id}/update`, {
            size_id,
            quantity
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': cookies.get('i18next')
            },
        })
        if (data?.status === 200) {
            message.success(data?.message)
            await fetchCart()
            await setLoading(false)
        } else {
            setDelete(false)
        }



    }
    const deleteProduct = async (id) => {
        setLoadingId(id)
        setDelete(true)

        const url = `https://holmz.rightclick.com.sa/api/cart/${id}/delete`;

        const { data } = await axios.delete(url, {
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': cookies.get('i18next'),
                Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
        });

        if (data?.status === 200) {
            fetchCart();
            message.success(data?.message)
            setDelete(false)
        } else {
            setDelete(false)
        }
    };
    const total = cart.reduce((acc, item) => acc + item.price * item.quantity, 0);

    const handleCheckout = async (va) => {
        const values = await form.validateFields();
        console.log('Form values:', values);  // طباعة القيم المدخلة للتأكد


        setLoading(true)
        const totalAmount = cart.reduce((acc, item) => acc + item.price * item.quantity, 0);
        const items = cart.map((item, index) => ({
            product_id: item.product_id,
            size_id: item.size_id,
            name: item.name,
            quantity: item.quantity,
            amount: item.price,
        }));

        const formData = new FormData();
        formData.append('total_amount', totalAmount * 100);
        formData.append('currency', "SAR");
        formData.append('first_name', values?.first_name);
        formData.append('last_name', values?.last_name);
        formData.append('phone', values?.phone);
        formData.append('country', values?.country);
        formData.append('city', values?.city);
        formData.append('state', values?.state);
        formData.append('address', values?.address);
        formData.append('zip', values?.zip);

        items.forEach((item, index) => {
            formData.append(`items[${index}][id]`, item.product_id);
            formData.append(`items[${index}][size_id]`, item.size_id);
            formData.append(`items[${index}][name]`, item.name);
            formData.append(`items[${index}][quantity]`, item.quantity);
            formData.append(`items[${index}][amount]`, item.amount * 100);

        });

        const { data } = await axios.post('https://holmz.rightclick.com.sa/api/payment/create-intention', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Accept-Language': cookies.get('i18next'),
                Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
        });
        console.log(data);

        if (data.status === 200) {
            message.success('Payment intention created successfully!');

            setLoading(false)

            window.location.href = data?.form_url;
            // navigate('/payment-success');
        } else {
            message.error('Failed to create payment intention.');


            setLoading(false)
        }

    };

    return (
        <div className="app_checkout">
            <header>
                <h2>Checkout</h2>
            </header>
            <main>
                <Row gutter={[32, 32]} justify="center">
                    {/* Order Details */}
                    <Col xs={24} md={14}>
                        <h1 className='mb-4 header_details_product'> Order details</h1>
                        <div className="products_h products_h2">
                            <Row gutter={[16, 16]}>
                                {cart.map((product) => (
                                    <Col span={24} key={product.id}>
                                        <Card className="product_card" hoverable bordered={true}  >
                                            <Row gutter={15}>
                                                <Col span={6} className='flex justify-content-center align-items-center gap-4'>
                                                    {/* إضافة عداد الكمية هنا مع أزرار الزيادة والنقصان بشكل عمودي */}
                                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} className='app_btn_max_min'>
                                                        <Button
                                                            onClick={() => updateQuantity(1, product)}
                                                            style={{ backgroundColor: '#000', color: '#fff' }}
                                                            className='btn_max'
                                                            loading={(product.id === loadingId) && loading}
                                                            icon={<PlusOutlined />} />
                                                        <Input
                                                            value={product?.quantity}
                                                            style={{ textAlign: 'center' }}
                                                        />
                                                        <Button
                                                            className='btn_min'
                                                            onClick={() => updateQuantity(-1, product)}
                                                            loading={(product.id === loadingId) && loading}
                                                            style={{ backgroundColor: '#000', color: '#fff' }}
                                                            icon={<MinusOutlined />} />
                                                    </div>
                                                    <Image
                                                        preview={{ mask: <span></span> }}
                                                        src={product.image}
                                                        alt={product.name}
                                                        className="product_image"
                                                    />
                                                </Col>
                                                <Col span={18}>
                                                    <div className="product_info">
                                                        <Row className="info  ">
                                                            <Col xl={8} lg={8} md={24}>
                                                                <h3>{product.name}</h3>
                                                                <div className="rating flex gap-2 flex  ">
                                                                    <span className='rate_text'>{5}</span>
                                                                    <Rate disabled allowHalf defaultValue={5} />
                                                                </div>
                                                            </Col>
                                                            <Col xl={16} lg={16} md={24} className="content  m-0">
                                                                <p className='mt-2'> {product?.shot_description}</p>
                                                            </Col>
                                                        </Row>
                                                        <Row gutter={[0, 0]} className="product_details ">
                                                            <Col span={12} className='flex gap-4'>
                                                                <div className="flex flex-column">
                                                                    <span>Quantity</span>
                                                                    <h4 className='quantity'>{product.quantity}</h4>
                                                                </div>
                                                                <div className="flex flex-column">
                                                                    <span>Size</span>
                                                                    <h4 className='size_'>{product.size}</h4>
                                                                </div>
                                                            </Col>
                                                            <Col span={12} >
                                                                <div className=" flex justify-content-end pt-4">

                                                                    <div className='total_cost   position-static '>
                                                                        <div class="coste-orders flex justify-content-between align-items-center w-100">
                                                                            <div class="orders-info">
                                                                                <span class="icon"><Icons.orders /></span>
                                                                                <span class="amount">{product?.price?.toLocaleString()} $</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>

                                                <div className="delete_item_order" >
                                                    <Button onClick={() => deleteProduct(product?.id)} icon={<DeleteOutlined />} type='primary' loading={loadingId == product?.id && loadingDelete} shape='circle' />
                                                </div>
                                            </Row>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                        <Col span={24}  >
                            <div className="nums_product">
                                <strong>{cart?.length}</strong> <span>Products</span>
                            </div>
                        </Col>
                    </Col>

                    {/* Payment Details */}
                    <Col xs={24} md={10}>
                        <h1 className='mb-4 header_details_product'>Payment details</h1>
                        <Card className="payment-details-card">
                            <Form form={form} layout="vertical">
                                <div className="payment-methods">
                                    <img
                                        src="https://upload.wikimedia.org/wikipedia/commons/b/b5/PayPal.svg"
                                        alt="paypal"
                                        width={80}
                                    />
                                    <img
                                        src="https://upload.wikimedia.org/wikipedia/commons/4/41/Visa_Logo.png"
                                        alt="visa"
                                        width={80}
                                    />
                                </div>
                                <Row gutter={16}>
                                    <Col span={12}>
                                        <Form.Item name="first_name" label="First name">
                                            <Input placeholder="Your Name" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="last_name" label="Last name">
                                            <Input placeholder="Your Name" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item name="phone" label="Phone">
                                    <Input placeholder="Your phone" />
                                </Form.Item>
                                <Form.Item name="country" label="Country">
                                    <Input placeholder="Your Name" />
                                </Form.Item>
                                <Form.Item name="city" label="City">
                                    <Input placeholder="Your Name" />
                                </Form.Item>
                                <Form.Item name="state" label="State">
                                    <Input placeholder="Your Name" />
                                </Form.Item>
                                <Form.Item name="address" label="Address">
                                    <Input.TextArea cols={12} placeholder="Your Name" />
                                </Form.Item>

                                <Form.Item name="zip" label="Zip">
                                    <InputNumber className='w-100' placeholder="Your Name" />
                                </Form.Item>

                                <div className="total-price shadow-1">
                                    <h3>Total: {total?.toLocaleString()}</h3>
                                </div>

                                <Form.Item>
                                    <Button loading={loading} onClick={handleCheckout} type="primary" size='large' block className="pay-button">
                                        Pay Now
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </main>
        </div>
    );
};
