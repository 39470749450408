import { Button, Card } from 'antd';  // إضافة مكون Card
import { useContext, useEffect } from 'react';
import { Col, Row } from 'antd'; 
import { Link } from 'react-router-dom';
import Icons from 'constants/Icons';
import { SharedData } from 'context/CallApi';
import { LocalizationContext } from 'context/LangChange';
import img from 'constants/Img';
import './style.scss';
import { EditOutlined } from '@ant-design/icons';

const PersonalData = () => {
  let { isLang } = useContext(LocalizationContext);
  let { fetchPersonalData } = useContext(SharedData);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="app__PersonalData" style={{ padding: '40px 20px', maxWidth: '900px', margin: 'auto' }}>
      <Card 
        style={{ backgroundColor: '#f9f9f9', borderRadius: '10px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', padding: '20px' }}
        bodyStyle={{ padding: '40px' }}
      >
        <Row gutter={[16, 16]} align="middle">
          <Col xs={24} md={6} style={{ textAlign: 'center' }}>
            <div className="personal" style={{ width: '150px', height: '150px', borderRadius: '50%', overflow: 'hidden', margin: 'auto', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
              {
                fetchPersonalData.image ? 
                  <img src={`${fetchPersonalData.image}`} alt="Profile" className='w-100' style={{ objectFit: 'cover', width: '100%', height: '100%' }} /> :
                  <img src={img.personal} alt="Profile" className='w-100' style={{ objectFit: 'cover', width: '100%', height: '100%' }} />
              }
            </div>
          </Col>
          <Col xs={24} md={12} style={{ textAlign: 'center' }}>
            <h3 className="text-capitalize" style={{ fontSize: '24px', fontWeight: 'bold' }}>
              {fetchPersonalData?.name || 'User Name'}
            </h3>
          </Col>
          <Col xs={24} md={6} style={{ textAlign: 'center' }}>
            <Link to={`/account/edit`} state={{ data: fetchPersonalData }}>
              <Button icon={<EditOutlined />} type="primary"  >
                {isLang === "en" ? 'Edit profile' : 'تعديل الحساب'}
              </Button>
            </Link>
          </Col>
        </Row>
        <Row gutter={[16, 16]} justify="center" style={{ marginTop: '40px' }}>
          <Col xs={24} md={12} style={{ textAlign: 'center' }}>
            <div className="conatent" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Icons.CallFill style={{ fontSize: '30px', color: '#FFD700', marginRight: '10px' }} />
              <div>
                <div className="header" style={{ fontSize: '16px', fontWeight: 'bold' }}>
                  {isLang === "en" ? 'Mobile number' : 'رقم الجوال'}
                </div>
                <div className="body" dir="ltr" style={{ fontSize: '14px' }}>
                  {fetchPersonalData?.phone || '0100000000'}
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} md={12} style={{ textAlign: 'center' }}>
            <div className="conatent" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Icons.Email style={{ fontSize: '30px', color: '#FFD700', marginRight: '10px' }} />
              <div>
                <div className="header" style={{ fontSize: '16px', fontWeight: 'bold' }}>
                  {isLang === "en" ? 'E-mail' : 'البريد الالكتروني'}
                </div>
                <div className="body" style={{ fontSize: '14px' }}>
                  {fetchPersonalData?.email || 'user@example.com'}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

export default PersonalData;
