
import FavoritesPersonal from 'Pages/Admin/Favorites/FavoritesPersonal';
import MyOrders from 'Pages/Admin/MyOrders/MyOrders';
import MyOrdersDetails from 'Pages/Admin/MyOrders/MyOrdersDetails';
import ChangePasword from 'Pages/Admin/PersonalData/ChangePasword';
import EditAccount from 'Pages/Admin/PersonalData/EditAccount';
import PersonalData from 'Pages/Admin/PersonalData/PersonalData.jsx';
import Login from 'Pages/Auth/Login/Login';
import Register from 'Pages/Auth/Register/Register';
import OTP from 'Pages/Auth/Settings/OTP';
import ResetPassword from 'Pages/Auth/Settings/ResetPassword';
import About from 'Pages/Website/About/About';
import AboutHome from 'Pages/Website/Home/AboutHome';
import ExploreHome from 'Pages/Website/Home/Explore/ExploreHome';
import Home from 'Pages/Website/Home/Home';
import Product from 'Pages/Website/Home/product/Product';
import Cards from 'Pages/Website/Product/Cards/Cards.jsx';
import Favorite from 'Pages/Website/Product/Favorite/Favorite';
import ProdactDetails from 'Pages/Website/Product/ProdactDetails/ProdactDetails';
import Products from 'Pages/Website/Product/Products';
import { Checkout } from 'Pages/Website/checkout/Checkout';
import Agent from 'Pages/Website/contact-us/Agent';
import Contact from 'Pages/Website/contact-us/Contact';
import OrderStatus from 'Pages/Website/order/OrderStatus';
import { Footer } from 'components/Footer/Footer';
import NavBar from 'components/Navbar/NavBar';
import Message from 'components/SocialMedia/Message';
import Account from 'layout/Account';
import Auth from 'layout/Auth';
import Holmes from 'layout/Holmes';


const Component = {
  // Layout
  Holmes,
  MyOrdersDetails,
  NavBar,
  Checkout,
  MyOrders,
  Footer,
  Home,
  AboutHome,
  OrderStatus,
  ExploreHome,
  Product,
  Auth,
  Login,
  Register,
  About,
  Products,
  Agent,
  Contact,
  Message,
  OTP,
  ResetPassword,
  ProdactDetails,
  Cards,
  Favorite, 
  Account,
  PersonalData,
  EditAccount,
  ChangePasword, 
  FavoritesPersonal
}

export default Component


