import { Player } from '@lottiefiles/react-lottie-player';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import loaderImg from '../../../assets/Payment_Failed.json';
import loaderImg2 from '../../../assets/Payment_Successful.json';
import './order.scss'
import axios from 'axios';
import cookies from 'js-cookie';
const OrderStatus = () => {
    const location = useLocation();
    const [params, setParams] = useState({
        id: '',
        pending: '',
        amount_cents: '',
        success: '',
        merchant_order_id: ''
    });

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        setParams({
            id: query.get('id'),
            pending: query.get('pending'),
            amount_cents: query.get('amount_cents'),
            success: query.get('success'),
            merchant_order_id: query.get('merchant_order_id'),
        });
        UpdateInvoice(query)
    }, [location]);

    const UpdateInvoice = async (query) => {
        let { data } = await axios.post(`https://holmz.rightclick.com.sa/api/payment/update-invoice`, {
            merchant_order_id: query.get('merchant_order_id'),
            success: query.get('success')
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Accept-Language': cookies.get('i18next')
            },
        })
        console.log(data);
        
    }
    useEffect(() => {
    }, [location]);


    const isSuccess = params.success === 'true';

    return (
        <>
            <div className={`app_order_status ${isSuccess ? 'success' : 'failed'}`}>
                {isSuccess ? (
                    <div className="success-message">
                        <div className="load_img">
                            <Player
                                className='Player'
                                src={loaderImg2}
                                autoplay
                                loop
                            />
                        </div>
                        <h2>Payment Successful</h2>
                        <p>Thank you! Your payment was completed successfully.</p>
                    </div>
                ) : (
                    <div className="failed-message">
                        <div className="load_img">
                            <Player
                                className='Player'
                                src={loaderImg}
                                autoplay
                                loop
                            />
                        </div>
                        <h2>Payment Failed</h2>
                        <p>Sorry, your payment did not go through. Please try again.</p>
                    </div>
                )}
            </div>
        </>
    );
}

export default OrderStatus;
