// utils/loginLogic.js
import axios from 'axios';
import { showNotification } from 'utils/notification';

export const loginUser = async (values, setLoading, fetchCart, navigate, t, checked, message, fetchPersonal) => {
    setLoading(true);
    if (localStorage.getItem('guest_id')) {
        let { data } = await axios.post(`https://holmz.rightclick.com.sa/api/auth/login`, {
            email: values.email,
            password: values.password,
            guest_id: localStorage.getItem('guest_id')
        });
        if (data?.status === 200) {
            localStorage.removeItem('guest_id');
            localStorage.setItem('token', data?.data?.token);
            fetchPersonal()
            fetchCart();
            setTimeout(() => {
                navigate(`/`);
            }, 500);
            setLoading(false);
            showSuccess(t('login_msg_success'), t);
            handleRememberMe(checked, values);
        } else {
            setLoading(false);
            showError(data?.message, t);
        }
    } else {
        let { data } = await axios.post(`https://holmz.rightclick.com.sa/api/auth/login`, values);
        if (data?.status === 200) {
            localStorage.setItem('token', data?.data?.token);
            fetchPersonal()
            setTimeout(() => {
                navigate(`/`);
            }, 500);
            setLoading(false);
            showSuccess(t('login_msg_success'), t);
            handleRememberMe(checked, values);
        } else {
            setLoading(false);
            showError(data?.message, t);
        }
    }
};

export const forgetPassword = async (values, setLoadForgetPassword, navigate, t, setVisible) => {
    try {
        setLoadForgetPassword(true);
        let { data } = await axios.post(`https://holmz.rightclick.com.sa/api/auth/forget-password`, values);

        setLoadForgetPassword(false);
        if (data?.status === 200) {
            showNotification('success', t('Verification_code'), data?.message)
            setTimeout(() => {
                setVisible(false);
                navigate('/holmes/reset-password');
            }, 1500);
        } else {
            showNotification('success', t('Verification_code'), data?.message)
        }
    } catch (error) {
        setLoadForgetPassword(false);
        if (error?.response?.status === 422) {
            showNotification('error', t('failed'), error?.response?.data?.message)
            if (error?.response?.data?.errors?.email) {
                showNotification('error', t('failed'), error?.response?.data?.errors?.email)
            }
        }
    }
};

const handleRememberMe = (checked, values) => {
    if (checked) {
        localStorage.setItem('rememberMe', 'true');
        localStorage.setItem('rememberMeEmail', values?.mobile);
        localStorage.setItem('rememberMePassword', values?.password);
    } else {
        localStorage.removeItem('rememberMe');
        localStorage.removeItem('rememberMeEmail');
        localStorage.removeItem('rememberMePassword');
    }
};

const showError = (data, t) => {
    showNotification('error', t('failed'), data)
};

const showSuccess = (data, t) => {
    showNotification('success', t('success'), data)
};
