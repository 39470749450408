import React, { useEffect, useState } from 'react';
import './orders.scss';
import { Col, Row, Card, Button, Divider, Rate, Tag } from 'antd';
import Icons from 'constants/Icons';
import img from 'constants/Img';
import { Steps, Typography } from "antd";
import { ClockCircleOutlined } from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import cookies from 'js-cookie';
const { Step } = Steps;
const { Text } = Typography;
const MyOrdersDetails = () => {
  let { id } = useParams()
  const products = [
    {
      id: 1,
      name: 'Product',
      rating: 4,
      quantity: 3,
      size: '100 ',
      price: 490,
      image: img.test1,
    },
    {
      id: 2,
      name: 'Product',
      rating: 4.6,
      quantity: 1,
      size: '100 ',
      price: 220,
      image: img.test1,
    },
    {
      id: 3,
      name: 'Product',
      rating: 2.5,
      quantity: 2,
      size: '100 ',
      price: 310,
      image: img.test2,
    },
    {
      id: 4,
      name: 'Product',
      rating: 3.3,
      quantity: 4,
      size: '100 ',
      price: 720,
      image: img.test2,

    },
  ];
  const [data, setData] = useState([]);
  const fetchData = async () => {
    const url = `https://holmz.rightclick.com.sa/api/orders/${id}`;
    let { data } = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': cookies.get('i18next')
      }
    })
    if (data?.status === 200) {
      setData(data?.data);
    }
  }
  useEffect(() => {
    fetchData()
  }, [])
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate(); // اليوم
    const month = date.getMonth() + 1; // الشهر (0 يعني يناير، لازم نضيف 1)
    const year = date.getFullYear(); // السنة
    return `${month}/${day}/${year}`; // صيغة التاريخ المطلوبة (MM/DD/YYYY)
  };

  return (
    <div className='app_order_details'>
      <h1>Order Details</h1>
      <div className="content">
        <Row gutter={100}>
          <Col span={10}>
            <Row gutter={[15, 15]}>
              <Col span={24}>
                <div className="order_header">
                  <div className="order_id">
                    <p className='order_detsils_header'>Order ID</p>
                    <h2>{data?.id}</h2>
                  </div>
                  <Button className="cancel_button">Cancel Order</Button>
                </div>
              </Col>
              <Divider style={{ borderColor: '#00000029', }} />
              <Col span={24}>
                <div className="order_date flex flex-row justify-content-between align-items-center">
                  <p className='order_detsils_header'>Order Date</p>
                  <h2>{data ? formatDate(data.status_time) : 'Loading...'}</h2>
                </div>
              </Col>
              <Divider style={{ borderColor: '#00000029', }} />
              <Col span={24} className='flex flex-row justify-content-between'>
                <div className="delivery_phases flex flex-column">
                  <p className='order_detsils_header'>Payment</p>
                  {
                    data.invoice_status === 0 ? <Tag className="  mt-3"  color="error">
                      error
                    </Tag> :
                      <Tag className="  mt-3"  color="success">
                        success
                      </Tag>
                  } 
                </div>
                <div className="delivery_phases">
                  <p className='order_detsils_header'>Delivery Phases</p>
                  <div className="delivery_phase mt-3">
                    <Button  >
                      <span>Step 3</span> <strong>Delivery In Progress</strong>
                    </Button>
                  </div>
                </div>
              </Col>
              <Divider style={{ borderColor: '#00000029', }} />
              <Col span={24}>
                <div className='total_cost   position-static'>
                  <div class="coste-orders flex justify-content-between align-items-center w-100">
                    <span className='order_detsils_header'>Coste Orders</span>
                    <div class="orders-info">
                      <span class="icon"><Icons.orders /></span>
                      <span class="amount">{data?.total_cost?.toLocaleString()} SAR</span>
                    </div>
                  </div>
                </div>
              </Col>
              <Divider style={{ borderColor: '#00000029', }} />
              {/* Add customer details */}
              <Col span={24}>
                <div className="customer_data">
                  <h2>Customer Data</h2>
                  <Row gutter={[5, 5]} className='customer_info shadow-2 '>
                    <Col span={12}>
                      <div className="customer_field">
                        <p className="order_detsils_header">First Name</p>
                        <h2>{data?.shipping_details?.first_name}</h2>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="customer_field">
                        <p className="order_detsils_header">Last Name</p>
                        <h2>{data?.shipping_details?.last_name}</h2>

                      </div>
                    </Col>
                    <Col span={24}>
                      <div className="customer_field">
                        <p className="order_detsils_header">Phone Number</p>
                        <h2>+{data?.shipping_details?.phone}</h2>
                      </div>
                    </Col>
                    <Divider style={{ borderColor: '#00000029', marginBottom: '15px' }} />
                    <Col span={12}>
                      <div className="customer_field">
                        <p className="order_detsils_header">Country</p>
                        <h2>{data?.shipping_details?.country}</h2>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="customer_field">
                        <p className="order_detsils_header">City</p>
                        <h2>{data?.shipping_details?.city}</h2>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="customer_field">
                        <p className="order_detsils_header">State</p>
                        <h2>{data?.shipping_details?.state}</h2>
                      </div>
                    </Col>
                    <Col span={24}>
                      <div className="customer_field">
                        <p className="order_detsils_header">Location</p>
                        <h2>{data?.shipping_details?.address}</h2>
                      </div>
                    </Col>
                    <Divider style={{ borderColor: '#00000029', marginBottom: '15px' }} />
                    <Col span={24}>
                      <div className="customer_field customer_field_Zip">
                        <p className="order_detsils_header">Zip Code</p>
                        <h2>{data?.shipping_details?.zip}</h2>

                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={14}>
            <div className="app_order_prodacts_details">
              <header className='flex justify-content-between  mb-4'>
                <h1>Products</h1>
                <div className="num_product flex justify-content-center align-items-center shadow-4">
                  <span>{data?.products?.length}</span>
                </div>
              </header>
              <div className="products_h">
                <Row gutter={[16, 16]}>
                  {data?.products?.map((product) => (
                    <Col span={24} key={product.id}>
                      <Card className="product_card" hoverable bordered={true}  >
                        <Link to={`/products/${product?.product_id}`}>
                          <Row gutter={15}>
                            <Col span={5} className='flex justify-content-center align-items-center  '>
                              <img src={product.image} alt={product.name} className="product_image" />
                            </Col>
                            <Col span={19}>
                              <div className="product_info">
                                <Row className="info  ">
                                  <Col xl={10} lg={10} md={24}>
                                    <h3>{product.name}</h3>
                                    <div className="rating flex gap-2 flex  "> 
                                      <Rate disabled allowHalf defaultValue={4.5} />
                                    </div>
                                  </Col>
                                  <Col xl={14} lg={14} md={24} className="content  m-0">
                                    <p className='mt-2'> {product?.shot_description}</p>
                                  </Col>
                                </Row>
                                <Row gutter={[0, 0]} className="product_details ">
                                  <Col span={12} className='flex gap-4'>
                                    <div className="flex flex-column">
                                      <span className='_dark'>Quantity</span>
                                      <h4 className='quantity'>{product.quantity}</h4>
                                    </div>
                                    <div className="flex flex-column">
                                      <span className='_dark'>Size</span>
                                      <h4 className='size_'>{product.size}</h4>
                                    </div>
                                  </Col>
                                  <Col span={12} >
                                    <div className=" flex justify-content-end pt-4">

                                      <div className='total_cost   position-static '>
                                        <div class="coste-orders flex justify-content-between align-items-center w-100">
                                          <div class="orders-info">
                                            <span class="icon"><Icons.orders /></span>
                                            <span class="amount">{product.price?.toLocaleString()} <small>SAR</small></span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </Link>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </div>
            </div>
          </Col>
        </Row>
        <Row className='mt-5 app_order_tracking' >
          <Col span={15}>
            <div className='mt-5' style={{ backgroundColor: "#fafafa", borderRadius: "8px" }}>
              <h2>Tracking details</h2>
              <p>View and update delivery information for your item</p>

              <Row>
                <Col span={24}>
                  <Steps
                    direction="vertical"
                    current={1}
                    className="animated-steps"
                    style={{ background: "#fafafa" }}
                  >
                    <Step
                      title="Placed"
                      description={<Text type="secondary">Placed on 30th Sep</Text>}
                    />
                    <Step
                      title={<Text  >Confirmed</Text>}
                      description={
                        <>
                          <Text strong>on time</Text>
                          <br />
                          <Text>Your order is going through its packaging process. We will email you when it is packed and dispatched to the final hub.</Text>
                        </>
                      }
                    />
                    <Step
                      title="Delivery"
                      description="Delivery by 5th-15th Oct"
                    />
                  </Steps>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={9} className=' flex justify-content-end'>
            <img src={img.delivery} alt="" className='w-100' srcset="" />
          </Col>
        </Row>

      </div>
    </div>
  );
};

export default MyOrdersDetails;
