import { Button, Col, Rate, Card, Row } from 'antd';
import axios from 'axios';
import Icons from 'constants/Icons';
import { SharedData } from 'context/CallApi';
import cookies from 'js-cookie';
import { useContext } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './favorite.scss';
import { TagOutlined } from '@ant-design/icons';

const Favorite = () => {

  let { fetchHome, fetchCart, favourite } = useContext(SharedData)
  let { fetchFavourite, fetchProductsData } = useContext(SharedData)
  let { t } = useTranslation();





  const deleteProduct = async (id) => {
    const url = `https://holmz.rightclick.com.sa/api/favourite/${id}/delete`;
    let { data } = await axios.delete(url, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': cookies.get('i18next'),
        Authorization: 'Bearer ' + localStorage.getItem('token'),

      }
    })
    if (data?.status === 200) {
      fetchFavourite()
      fetchProductsData()
      fetchCart()
    }
  }

  const deleteAllProduct = async () => {
    const url = `https://holmz.rightclick.com.sa/api/favourite/destroy`;
    let { data } = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': cookies.get('i18next'),
        Authorization: 'Bearer ' + localStorage.getItem('token'),

      }
    })

    if (data?.status) {
      fetchFavourite()
      fetchProductsData()
      fetchCart()
      toast.success(data?.message)
    }
  }

  return (
    <div className='app__favorite'>
      <div className="app__slider bg-black">
        <img src={fetchHome.slider_video} className='w-100 ' alt="" srcset="" />
      </div>

      <div className="container_cart">
        <header className='app_cart_header flex justify-content-between align-items-center'>

          <div className="remove-all-favorite" onClick={deleteAllProduct}>
            <span>Remove All</span>
          </div>

          <div className="Contact-header flex gap-4 justify-content-center align-items-center">
            <Icons.Favorite />
            <span>{favourite?.length}</span>
          </div>

          <div className="icons  cursor-pointer">
            <Icons.Close />
          </div>

        </header>

        <body className='app__favorite-body mt-2 bg-transparent'>
          <div className="app__about_banner app__about_explore flex justify-content-center flex-column">
            <Row gutter={[16, 16]}>
              {
                favourite?.map((item, index) => (
                  <Col xl={6} lg={6} md={12} sm={24} key={index} className='mt-4  '>
                    <Card
                      hoverable
                      cover={<img alt={`product-${index}`} src={item.image} />}
                    >
                      <Card.Meta
                        title={<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <span>{item?.name}</span>
                          <span>{item?.price?.toLocaleString()} <small>SAR</small></span>
                        </div>}
                        description={item?.shot_description?.split(' ')?.slice(0, 5)?.join(' ')}
                      />
                      <div className='rating_section mt-3'>
                        <Rate disabled allowHalf defaultValue={5} />
                      </div>
                      <Link to={`/products/${item.id}`}>
                        <Button type='primary' className='w-100 mt-3 ' icon={<TagOutlined />}>Buy </Button>
                      </Link>
                      <div className="icons_close shadow-lg" onClick={() => deleteProduct(item?.id)}>
                        <Icons.Close />
                      </div>
                    </Card>

                  </Col>
                ))
              }
            </Row>
          </div>
        </body>
      </div>
    </div>
  )
}

export default Favorite
