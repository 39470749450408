import axios from 'axios';
import { default as cookies } from 'js-cookie';
import { Button, Card, Row, Col, Rate, message, Carousel } from 'antd'; // استيراد Carousel
import { HeartOutlined, HeartFilled, TagOutlined } from '@ant-design/icons';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { SharedData } from 'context/CallApi';
import './ExploreHome.scss';

const ExploreHome = ({ data }) => {
  const { fetchFavourite, fetchProductsData } = useContext(SharedData);
  const token = localStorage.getItem('token');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // تابع لتحديد إذا كان الجهاز هو موبايل بناءً على العرض
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // اعتبر أن الأجهزة الصغيرة هي التي يقل عرضها عن 768px
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleFavourite = async (item) => {
    if (token) {
      if (item?.in_favourite === 0) {
        const url = `https://holmz.rightclick.com.sa/api/favourite/add`;
        let { data } = await axios.post(url, { product_id: item.id }, {
          headers: {
            'Content-Type': 'application/json',
            'Accept-Language': cookies.get('i18next'),
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          }
        });

        if (data?.status === 200) {
          message.success(data?.message);
          fetchFavourite();
          fetchProductsData();
        }

      } else if (item?.in_favourite === 1) {
        deleteProduct(item.id);
      }

    } else {
      navigate('/holmes/sign-in')
    }
  };

  const deleteProduct = async (id) => {
    const url = `https://holmz.rightclick.com.sa/api/favourite/${id}/delete`;
    let { data } = await axios.delete(url, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': cookies.get('i18next'),
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      }
    });

    if (data?.status === 200) {
      fetchFavourite();
      fetchProductsData();
      message.success(data?.message);
    }
  };

  const header = (item) => (
    <img alt="Card" src={item.image} className="product-image" />
  );

  const footer = (item) => (
    <div className='footer-btn'>
      <Link to={`/products/${item.id}`}>
        <Button type="primary" className="buy-button px-3" size="" icon={<TagOutlined />}>
          Buy
        </Button>
      </Link>
      <Button
        type="default"
        className="shadow-2 favourite-button"
        shape='circle'
        icon={item.in_favourite === 1 ? <HeartFilled /> : <HeartOutlined />}
        onClick={() => toggleFavourite(item)}
      />
    </div>
  );

  return (
    <div className="app-about-banner app-about-explore flex-column mb-4">
      <div className='Container_app'>
        <h1 className='explore-header text-center'>{t('best_seller')}</h1>

        {isMobile ? (
          // Slider للموبايل
          <Carousel dots>
            {data?.map((item) => (
              <div key={item.id} className='carousel-item'>
                <Card
                  cover={header(item)}
                  className='product-card w-100 p-2'
                  hoverable
                >
                  <div className='card-content'>
                    <div className='product-info'>
                      <h3 className='product-name'>{item?.name}</h3>
                      <span className='product-price'>{item?.price?.toLocaleString()} <small>SAR</small></span>
                    </div>
                    <p className='product-description'>{item?.shot_description}</p>
                    <div className="rating">
                      <Rate allowHalf disabled value={5} />
                    </div>
                    <div className='footer'>
                      {footer(item)}
                    </div>
                  </div>
                </Card>
              </div>
            ))}
          </Carousel>
        ) : (
          // عرض الشبكة لأجهزة الكمبيوتر
          <Row gutter={[16, 16]}>
            {data?.map((item) => (
              <Col xl={6} lg={6} md={12} sm={24} key={item.id} className='mt-3 w-100'>
                <Card
                  cover={header(item)}
                  className='product-card w-100 p-2'
                  hoverable
                >
                  <div className='card-content'>
                    <div className='product-info'>
                      <h3 className='product-name'>{item?.name}</h3>
                      <span className='product-price'>{item?.price?.toLocaleString()} <small>SAR</small></span>
                    </div>
                    <p className='product-description'>{item?.shot_description}</p>
                    <div className="rating">
                      <Rate allowHalf disabled value={5} />
                    </div>
                    <div className='footer'>
                      {footer(item)}
                    </div>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        )}
      </div>
    </div>
  );
};

export default ExploreHome;
