import { Button, Card, Col, Empty, Pagination, Row, Tabs, Tag } from 'antd';
import Icons from 'constants/Icons';
import img from 'constants/Img';
import { SharedData } from 'context/CallApi';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './orders.scss';
import axios from 'axios';
import cookies from 'js-cookie';

const { TabPane } = Tabs;


const MyOrders = () => {
    let { t } = useTranslation();
    let { favourite, cart, fetchPersonalData } = useContext(SharedData);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 8; // عدد المنتجات في الصفحة

    const [newOrders, setNewOrders] = useState([]);
    const [doneOrders, setDoneOrders] = useState([]);
    const [canceledOrders, setCanceledOrders] = useState([]);

    const fetchOrders = async () => {
        const url = `https://holmz.rightclick.com.sa/api/orders`;
        let { data } = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('token'),
                'Accept-Language': cookies.get('i18next')
            }
        })
        console.log(data?.data);

        if (data?.status === 200) {
            setNewOrders(data?.data?.new_orders);
            setDoneOrders(data?.data?.done_orders);
            setCanceledOrders(data?.data?.canceled_orders);
        }
    }
    const callback = (key) => {
        setCurrentPage(1)
    };
    useEffect(() => {
        fetchOrders()
    }, [])

    // تحديد المنتجات لكل صفحة
    const newOrdersPage = newOrders?.data?.slice((currentPage - 1) * pageSize, currentPage * pageSize);
    const doneOrdersPage = doneOrders?.data?.slice((currentPage - 1) * pageSize, currentPage * pageSize);
    const canceledOrdersPage = canceledOrders?.data?.slice((currentPage - 1) * pageSize, currentPage * pageSize);

    // التعامل مع تغيير الصفحة
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate(); // اليوم
        const month = date.getMonth() + 1; // الشهر (0 يعني يناير، لازم نضيف 1)
        const year = date.getFullYear(); // السنة
        return `${month}/${day}/${year}`; // صيغة التاريخ المطلوبة (MM/DD/YYYY)
    };
    return (
        <div className='orders_content'>
            <Tabs defaultActiveKey="1" className='mt-4' onChange={callback}>
                <TabPane tab={t('new_order')} key="1">
                    {
                        newOrders?.total_orders > 0 ? <>
                            <div className='order_summary'>
                                <h3>({newOrders?.total_orders} Orders)</h3>
                                <Row gutter={16}>
                                    {newOrdersPage?.map((item, index) => (
                                        <Col span={6} key={index} className='mt-4 '>
                                            <Card
                                                hoverable
                                                cover={<img alt={`product-${index}`} src={img.BgPerfume5} />}
                                            >
                                                <Card.Meta
                                                    title={<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <span>{item?.id}</span>
                                                        <span>{item?.total_cost?.toLocaleString()} <small>SRA</small> </span>
                                                    </div>}
                                                    description={<div className='flex justify-content-between align-items-center mb-3 mt-34'>
                                                        {
                                                            item.status == 0 ? <Tag color="yellow"  >
                                                                pending
                                                            </Tag> :
                                                                <Tag color="success">
                                                                    success
                                                                </Tag>
                                                        }

                                                        {formatDate(item.status_time)}
                                                    </div>}
                                                />
                                                <Link to={`/orders/2`}>
                                                    <Button type='primary' className='w-100 mt-3'>Details </Button>
                                                </Link>
                                            </Card>

                                        </Col>
                                    ))}
                                </Row>
                                <div className="flex justify-content-center align-items-center">
                                    <Pagination
                                        className="mt-4"
                                        current={currentPage}
                                        pageSize={pageSize}
                                        total={newOrders?.total_orders}
                                        onChange={handlePageChange}
                                    />
                                </div>
                            </div>
                            <div className='total_cost'>
                                <div class="coste-orders">
                                    <span className='title'>Coste Orders</span>
                                    <div class="orders-info">
                                        <span class="icon"><Icons.orders /></span>
                                        <span class="amount">{newOrders?.total_cost?.toLocaleString()} <small>SAR</small> </span>
                                    </div>
                                </div>
                            </div>
                        </> : <div className="empty-orders-message mt-4">
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'لا توجد طلبات حتى الآن.'} />
                            <Link to="/products" className='flex justify-content-center'>
                                <Button type="primary" className="mt-3">ابدأ التسوق الآن</Button>
                            </Link>
                        </div>

                    }

                </TabPane>
                <TabPane tab={t('done_order')} key="2">
                    {
                        doneOrders?.total_orders > 0 ? <>
                            <div className='order_summary'>
                                <h3>({doneOrders?.total_orders} Orders)</h3>
                                <Row gutter={16}>
                                    {doneOrdersPage?.map((item, index) => (
                                        <Col span={6} key={index} className='mt-4 '>
                                            <Card
                                                hoverable
                                                cover={<img alt={`product-${index}`} src={img.BgPerfume5} />}
                                            >
                                                <Card.Meta
                                                    title={<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <span>{item?.id}</span>
                                                        <span>{item?.total_cost?.toLocaleString()} <small>SRA</small> </span>
                                                    </div>}
                                                    description={<div className='flex justify-content-between align-items-center mb-3 mt-34'>
                                                        {
                                                            item.status == 0 ? <Tag color="yellow"  >
                                                                pending
                                                            </Tag> :
                                                                <Tag color="success">
                                                                    success
                                                                </Tag>
                                                        }

                                                        {formatDate(item.status_time)}
                                                    </div>}
                                                />
                                                <Link to={`/orders/2`}>
                                                    <Button type='primary' className='w-100 mt-3'>Details </Button>
                                                </Link>
                                            </Card>

                                        </Col>
                                    ))}
                                </Row>
                                <div className="flex justify-content-center align-items-center">
                                    <Pagination
                                        className="mt-4"
                                        current={currentPage}
                                        pageSize={pageSize}
                                        total={newOrders?.total_orders}
                                        onChange={handlePageChange}
                                    />
                                </div>
                            </div>
                            <div className='total_cost'>
                                <div class="coste-orders">
                                    <span className='title'>Coste Orders</span>
                                    <div class="orders-info">
                                        <span class="icon"><Icons.orders /></span>
                                        <span class="amount">{doneOrders?.total_cost?.toLocaleString()} <small>SAR</small> </span>
                                    </div>
                                </div>
                            </div>
                        </> : <div className="empty-orders-message mt-4">
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'لا توجد طلبات حتى الآن.'} />
                            <Link to="/products" className='flex justify-content-center'>
                                <Button type="primary" className="mt-3">ابدأ التسوق الآن</Button>
                            </Link>
                        </div>

                    }
                </TabPane>
                <TabPane tab={t('rejected_order')} key="3">
                    {
                        canceledOrders?.total_orders > 0 ? <>
                            <div className='order_summary'>
                                <h3>({canceledOrders?.total_orders} Orders)</h3>
                                <Row gutter={16}>
                                    {canceledOrdersPage?.map((item, index) => (
                                        <Col span={6} key={index} className='mt-4 '>
                                            <Card
                                                hoverable
                                                cover={<img alt={`product-${index}`} src={img.BgPerfume5} />}
                                            >
                                                <Card.Meta
                                                    title={<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                        <span>{item?.id}</span>
                                                        <span>{item?.total_cost?.toLocaleString()} <small>SRA</small> </span>
                                                    </div>}
                                                    description={<div className='flex justify-content-between align-items-center mb-3 mt-34'>
                                                        {
                                                            item.status == 0 ? <Tag color="yellow"  >
                                                                pending
                                                            </Tag> :
                                                                <Tag color="success">
                                                                    success
                                                                </Tag>
                                                        }

                                                        {formatDate(item.status_time)}
                                                    </div>}
                                                />
                                                <Link to={`/orders/2`}>
                                                    <Button type='primary' className='w-100 mt-3'>Details </Button>
                                                </Link>
                                            </Card>

                                        </Col>
                                    ))}
                                </Row>
                                <div className="flex justify-content-center align-items-center">
                                    <Pagination
                                        className="mt-4"
                                        current={currentPage}
                                        pageSize={pageSize}
                                        total={newOrders?.total_orders}
                                        onChange={handlePageChange}
                                    />
                                </div>
                            </div>
                            <div className='total_cost'>
                                <div class="coste-orders">
                                    <span className='title'>Coste Orders</span>
                                    <div class="orders-info">
                                        <span class="icon"><Icons.orders /></span>
                                        <span class="amount">{canceledOrders?.total_cost?.toLocaleString()} <small>SAR</small> </span>
                                    </div>
                                </div>
                            </div>
                        </> : <div className="empty-orders-message mt-4">
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'لا توجد طلبات حتى الآن.'} />
                            <Link to="/products" className='flex justify-content-center'>
                                <Button type="primary" className="mt-3">ابدأ التسوق الآن</Button>
                            </Link>
                        </div>
                    }
                </TabPane>
            </Tabs>
        </div>
    );
};

export default MyOrders;
