import { Button, Col, Form, Input, Row, notification } from 'antd'; // Using Ant Design components
import axios from 'axios';
import img from 'constants/Img';
import { LocalizationContext } from 'context/LangChange';
import { useFormik } from 'formik';
import cookies from 'js-cookie';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import './contact.scss';
import Component from 'constants/Component';

const { TextArea } = Input;

const Agent = () => {
    const { t } = useTranslation();
    const { isLang } = useContext(LocalizationContext);
    const [isLoading, setIsLoading] = useState(false);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(isLang === "en" ? 'Your name is required' : 'يرجى إدخال الاسم'),
        email: Yup.string().email(isLang === "ar" ? 'يرجى إدخال عنوان بريد إلكتروني صحيح' : 'Please enter a valid email address').required(isLang === "en" ? 'The email field is required.' : 'يرجى إدخال عنوان بريد إلكتروني'),
        phone: Yup.string().required(isLang === "en" ? 'The phone field is required.' : 'يرجى إدخال رقم الهاتف'),
        reason: Yup.string().required(isLang === "en" ? 'The reason field is required.' : 'يرجى إدخال السبب'),
        message: Yup.string().required(isLang === "en" ? 'The message field is required.' : 'يرجى إدخال الرسالة'),
    });

    useEffect(() => {
        window.scroll(0, 0);
        document.title = t('contact_title');
    }, [t]);

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            reason: '',
            message: '',
            type: 1
        },
        validationSchema: validationSchema,
        onSubmit: async (values, { resetForm }) => {
            setIsLoading(true);
            try {
                const { data } = await axios.post(`https://holmz.rightclick.com.sa/api/home/send-contact-us`, values, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept-Language': cookies.get('i18next')
                    }
                });

                if (data?.status === 200) {
                    showSuccess(data?.message);
                    resetForm();
                    window.scroll(0, 0);
                    setIsLoading(false);
                } else {
                    showError(data?.message);
                    setIsLoading(false);
                }

            } catch (error) {
                setIsLoading(false);
            }
        }
    });

    const showError = (data) => {
        notification.error({
            message: isLang === "en" ? 'Error' : 'خطأ',
            description: data,
        });
    };

    const showSuccess = (data) => {
        notification.success({
            message: isLang === "en" ? 'Success' : 'نجاح',
            description: data,
        });
    };

    return (
        <div>
            <div className="app__contact_us overflow-x-hidden">
                <Row gutter={[16, 16]}>
                    <Col xl={14} lg={14} md={12} sm={24} xs={24} className="h-100">
                        <div className="contact_form">
                            <header>
                                <h1>{t('nav_agent')}</h1>
                            </header>

                            <section className="container-content">
                                <Form
                                    layout="vertical"
                                    onFinish={formik.handleSubmit}
                                >
                                    <Row gutter={[16, 16]}>
                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <Form.Item
                                                label={t('label_name')}
                                                validateStatus={formik.touched.name && formik.errors.name ? "error" : ""}
                                                help={formik.touched.name && formik.errors.name ? formik.errors.name : null}
                                            >
                                                <Input
                                                    name="name"
                                                    value={formik.values.name}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    placeholder={t('placeholder_name')}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <Form.Item
                                                label={t('label_email')}
                                                validateStatus={formik.touched.email && formik.errors.email ? "error" : ""}
                                                help={formik.touched.email && formik.errors.email ? formik.errors.email : null}
                                            >
                                                <Input
                                                    name="email"
                                                    value={formik.values.email}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    placeholder={t('placeholder_email')}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <Form.Item
                                                label={t('label_phone')}
                                                validateStatus={formik.touched.phone && formik.errors.phone ? "error" : ""}
                                                help={formik.touched.phone && formik.errors.phone ? formik.errors.phone : null}
                                            >
                                                <Input
                                                    name="phone"
                                                    value={formik.values.phone}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    placeholder={t('placeholder_phone')}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                                            <Form.Item
                                                label={t('label_reason')}
                                                validateStatus={formik.touched.reason && formik.errors.reason ? "error" : ""}
                                                help={formik.touched.reason && formik.errors.reason ? formik.errors.reason : null}
                                            >
                                                <Input
                                                    name="reason"
                                                    value={formik.values.reason}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    placeholder={t('placeholder_reason')}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                                            <Form.Item
                                                label={t('label_message')}
                                                validateStatus={formik.touched.message && formik.errors.message ? "error" : ""}
                                                help={formik.touched.message && formik.errors.message ? formik.errors.message : null}
                                            >
                                                <TextArea
                                                    name="message"
                                                    value={formik.values.message}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    rows={5}
                                                    placeholder={t('placeholder_message')}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col span={24}>
                                            <div className="btn__seeMore" >
                                                <Button type="primary" htmlType="submit" size='' loading={isLoading}>
                                                    {t('btn_submit')}
                                                </Button>
                                            </div>
                                        </Col>
                                        <Col span={24}>
                                            <hr />
                                            <div className="send-message">
                                                <div className="Group d-flex flex-row justify-content-between align-item-center mb-5">
                                                    <Component.Message />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </section>
                        </div>
                    </Col>

                    <Col xl={10} lg={10} md={12} sm={24} xs={24} className="contact_overlay m-0 p-0">
                        <img src={img.Agentbg} alt="" className="w-100 object-fit-contain" />
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default Agent;
