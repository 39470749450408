import React from 'react';
import { Col, Row, Button, Rate } from 'antd'; // استبدال من react-bootstrap إلى antd
import { HeartOutlined, HeartFilled, DeleteOutlined } from '@ant-design/icons'; // استيراد الأيقونات من Ant Design

const ProductItem = ({ item, sizeActive, setSizeActive, toggleFavourite, deleteProduct }) => (
  <section className='item mt-4 rounded-2 m-auto  ' style={{ boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', borderRadius: '10px', background: '#fff' }}>
    <Row gutter={[16, 16]}>
      {/* صورة المنتج */}
      <Col xl={6} lg={6} md={12} sm={24} className='item_image flex justify-content-center'>
        <img src={item.image} className='w-100 object-fit-contain' alt="product" style={{ maxHeight: '200px', borderRadius: '8px' }} />
      </Col>

      {/* تفاصيل المنتج */}
      <Col xl={18} lg={18} md={12} sm={24} className='item_icons_close'>
        <div className="item_details">
          {/* اسم المنتج والمفضلة */}
          <div className="flex justify-content-between align-items-center  ">
            <div className="header">
              <span style={{ fontSize: '14px', color: '#666' }}>Product Name</span>
              <h3 style={{ fontSize: '22px', fontWeight: '600' }}>{item.name}</h3>
            </div>
            <Button
              type={item.in_favourite === 1 ? "primary" : "default"}
              icon={item.in_favourite === 1 ? <HeartFilled /> : <HeartOutlined />}
              onClick={() => toggleFavourite(item)}
            />
          </div>

          {/* التقييم */}
          <div className="rate flex align-items-center mb-3">
            <span style={{ fontSize: '16px', fontWeight: '500', marginRight: '8px' }}>6</span>
            <Rate disabled value={5} style={{ fontSize: '16px' }} />
          </div>

          {/* وصف المشروع والحجم والسعر */}
          <div className="flex justify-content-between align-items-center">
            <div className="footer">
              <span >About Project</span>
              <p >{item.shot_description}</p>
            </div>

            {/* صندوق الحجم والسعر */}
            <div className="size-box" style={{ padding: '15px', backgroundColor: '#FFFBE6', borderRadius: '10px', textAlign: 'center', border: '2px solid #FFC107', width: '150px', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)' }}>
              <h3 style={{ fontSize: '20px', fontWeight: '700', margin: '0', color: '#333' }}>{item.price?.toLocaleString()} <span style={{ fontSize: '16px' }}>SAR</span></h3>
              <span style={{ fontSize: '14px', color: '#666' }}>{item.size} <span>mg</span></span>
            </div>
          </div>
        </div>

        {/* أيقونة الحذف */}
        <div className="icons ms-auto cursor-pointer" onClick={() => deleteProduct(item.id)}>
          <Button icon={<DeleteOutlined />} type="text" danger />
        </div>
      </Col>
    </Row>
  </section>
);

export default ProductItem;
