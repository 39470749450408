import axios from 'axios';
import { useFormik } from 'formik';
import { default as cookies } from 'js-cookie';
import { Button, Input, Alert, Row, Col } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useValidation from 'validation/useValidation';

const ChangePasword = ({ isLang, toast }) => {
    let { validateChangePassword } = useValidation();
    const [handelOpenPassword, setHandelOpenPassword] = useState('password');
    const [handelOpenPassword1, setHandelOpenPassword1] = useState('password');
    const [handelOpenPassword2, setHandelOpenPassword2] = useState('password');
    let navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            old_password: '',
            new_password: '',
            password_confirmation: ''
        },
        validationSchema: validateChangePassword,
        onSubmit: async (values) => {
            try {
                const url = `https://holmz.rightclick.com.sa/api/auth/update-password`;
                let { data } = await axios.post(url, values, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Accept-Language': cookies.get('i18next'),
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    }
                });
                console.log(data);
                if (data?.status === 200) {
                    toast.current.show({ severity: 'success', summary: '', detail: data?.message });
                    setTimeout(() => {
                        navigate('/account/personal_data');
                    }, 1500);
                } else {
                    toast.current.show({ severity: 'error', summary: '', detail: data?.message });
                }
            } catch (error) {
                console.error("Error updating password:", error);
            }
        }
    });

    return (
        
        <form onSubmit={formik.handleSubmit} className="mt-4 w-100">
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <div className="change_password mt-60">
                        <h2>{isLang === "en" ? "Change Password" : "تغير كلمة المرور"}</h2>
                    </div>
                </Col>

                <Col span={24}>
                    <div className="flex flex-column gap-2 mt-3">
                        <label htmlFor="old_password" className="block mb-2">
                            {isLang === "en" ? "Old Password" : "كلمة المرور القديمة"}
                        </label>
                        <div className="password_open">
                            <Input.Password
                                id="old_password"
                                name="old_password"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.old_password}
                                className="w-full bg-light"
                                visibilityToggle
                            />
                        </div>
                        {formik.errors.old_password && formik.touched.old_password && (
                            <Alert message={formik.errors.old_password} type="error" showIcon />
                        )}
                    </div>
                </Col>

                <Col span={24}>
                    <div className="flex flex-column gap-2 mt-3">
                        <label htmlFor="new_password" className="block mb-2">
                            {isLang === "en" ? "New Password" : "كلمة المرور الجديدة"}
                        </label>
                        <div className="password_open">
                            <Input.Password
                                id="new_password"
                                name="new_password"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.new_password}
                                className="w-full bg-light"
                                visibilityToggle
                            />
                        </div>
                        {formik.errors.new_password && formik.touched.new_password && (
                            <Alert message={formik.errors.new_password} type="error" showIcon />
                        )}
                    </div>
                </Col>

                <Col span={24}>
                    <div className="flex flex-column gap-2 mt-3">
                        <label htmlFor="password_confirmation" className="block mb-2">
                            {isLang === "en" ? "Confirm Password" : "تأكيد كلمة المرور"}
                        </label>
                        <div className="password_open">
                            <Input.Password
                                id="password_confirmation"
                                name="password_confirmation"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.password_confirmation}
                                className="w-full bg-light"
                                visibilityToggle
                            />
                        </div>
                        {formik.errors.password_confirmation && formik.touched.password_confirmation && (
                            <Alert message={formik.errors.password_confirmation} type="error" showIcon />
                        )}
                    </div>
                </Col>

                <Col span={24}>
                    <div className="flex flex-column gap-2 mt-60 submit">
                        <Button type="primary" htmlType="submit" block size="large">
                            {isLang === "ar" ? "تغيير كلمة السر" : "Change Password"}
                        </Button>
                    </div>
                </Col>
            </Row>
        </form>
    );
};

export default ChangePasword;
