import Icons from 'constants/Icons';
// import { FetchDataContext } from 'context/FetchData';
import './style.scss';
const Message = () => {
    /* let { settings } = useContext(FetchDataContext) */

    return (
        <div className='group__conect  w-100 flex justify-content-between  gap-2'>
            <div className="email flex gap-3 justify-content-center align-items-center">
                <Icons.Email />
                <a rel="noreferrer" className='text-dark' target="_blank" href={`mailto:info@holmes.sa`}>info@holmes.sa</a>
            </div>

            <div className="callphone flex gap-3 justify-content-center align-items-center">
                <Icons.CallFill />
                {/*         <a dir='ltr' rel="noreferrer" target="_blank" href={`tel:${settings?.phone}`}>
                    {'+ '}{settings?.whats?.replace(/\D/g, '')?.replace(/(\d{3})(\d{2})(\d{3})(\d{4})/, '$1-$2-$3-$4')}
                </a> */}

                <a dir='ltr' rel="noreferrer" target="_blank" className='text-dark' href={`tel:+201063034637`}>
                    {'+ '}01063245454
                </a>
            </div>


        </div>
    )
}

export default Message

