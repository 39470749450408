import React from 'react';
import { Col, Row } from 'react-bootstrap';
import img from 'constants/Img';


const ProductItemSmall = ({ item, toggleFavourite, deleteProduct }) => (
    <Row className='mt-3 app__cart_sm_item'>
        <Col xl={4} lg={4} md={4} sm={4} xs={4} className='flex justify-content-center'>
            <img src={item.image} className='w-100 object-fit-contain' alt="" />
        </Col>
        <Col xl={8} lg={8} md={8} sm={8} xs={8}>
            <div className="header">
                <span>{item.name}</span>
                <h3 className='mt-2'>{String(item.shot_description).split(' ').slice(0,5).join(' ')} {" - "} <small>{item.size} <sub>mg</sub></small></h3>
                <h2 className='mt-4'>{item.price?.toLocaleString()} ريال</h2>
            </div>
        </Col>
        <div className="btn_cart_sm flex justify-content-between">
            <button onClick={() => {
                console.log(item.id);
                deleteProduct(item.id)
            }} className="button-13" role="button"><i className='pi pi-trash'></i></button>
            <button onClick={() => {
                toggleFavourite(item)
            }} className="button-13" role="button"><i className={`pi ${item.in_favourite === 1 ? 'pi-favorite' : ''} pi-heart-fill`}></i></button>
        </div>
    </Row>
);

export default ProductItemSmall;
