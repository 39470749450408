import axios from 'axios'
import cookies from 'js-cookie' 
import React, { useContext, useEffect, useState } from 'react'
 import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Component from 'constants/Component'
import { SharedData } from 'context/CallApi'
import { LocalizationContext } from 'context/LangChange'
import { TagOutlined } from '@ant-design/icons'  
 import { Button, Col, Row } from 'antd';  

const Products = () => {
    let { fetchMostProducts } = useContext(SharedData)
    let { isLang } = useContext(LocalizationContext);
    let { t } = useTranslation()
    const [data, setData] = useState([]);
    const fetchData = async () => {
        const url = `https://holmz.rightclick.com.sa/api/home/products?page=-1`;
        try {
            if (localStorage.getItem('token')) {
                let { data } = await axios.get(url, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept-Language': cookies.get('i18next'),
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    }
                })
                if (data?.status === 200) {
                    setData(data?.data?.data);
                }
            } else {
                let { data } = await axios.get(url, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept-Language': cookies.get('i18next'),
                    }
                })
                if (data?.status === 200) {
                    setData(data?.data?.data);
                }
            }

        } catch (error) {

        }
    }

    useEffect(() => {
        fetchData()
    }, [isLang])

    return (
        <div style={{ marginTop: '180px' }}>
            <Component.ExploreHome data={fetchMostProducts} />

            <div className="app__product_home">
                <header className='flex justify-content-center align-items-center flex-column'>
                    <h3>{t('product_title')}</h3>
                    <p>Lörem ipsum kombucha lävis dir divas. Mill ontoception. Leledes hångar. Janing nätdeklarant och valig. Euron sebelt inte bahåv bell sare. Fide anagram, att lamörede.</p>
                </header>

                {data?.length >= 0 && (
                    <section className='overflow-hidden mt-4 app__product_home_section2 mb-8'>
                        <Row gutter={[16, 16]}>
                            {data?.map((item, index) => (
                                <Col xl={12} lg={12} md={12} sm={24} className='mt-4' key={index}>
                                    <img src={item?.image} alt="" className='w-100 cart_image' />
                                    <Row gutter={[16, 16]}>
                                        <Col xl={10} lg={10} md={10} sm={24} className='card_style shadow'>
                                            <div className="card_info flex align-items-center justify-content-between w-100 ">
                                                <div className="header">
                                                    <h2>{item?.name}</h2>
                                                </div>
                                                <div className="footer">
                                                    <span>0{index + 1}</span>
                                                </div>
                                            </div>
                                            <section className='card_details flex flex-row justify-content-between align-items-center'>
                                                <div className="card_header">
                                                    <Link to={`/products/${item.id}`}>
                                                        <Button type="primary" className="buy-button px-3" icon={<TagOutlined />}>
                                                            {t('Buy')}
                                                        </Button>
                                                    </Link>
                                                </div>
                                                <div className="card_body flex justify-content-between flex-row align-items-center">
                                                    <div className="more">
                                                        <Link>{t('More')}</Link>
                                                    </div>
                                                </div>
                                            </section>
                                        </Col>

                                        <Col xl={12} lg={12} md={12} sm={24} className='app__card_description'>
                                            <div className="description">
                                                <section className='product_title mt-3'>
                                                    <span>{t('Description')}</span>
                                                </section>
                                                <section className='product_footer'>
                                                    <div className="card_body">
                                                        <span>{t('olfactive notes')}</span>
                                                        <p>{item?.shot_description}</p>
                                                    </div>
                                                </section>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            ))}
                        </Row>
                    </section>
                )}
            </div>
        </div>
    )
}

export default Products
