import { UserOutlined } from '@ant-design/icons';
import { Avatar, Badge, List } from 'antd';
import Icons from 'constants/Icons';
import { SharedData } from 'context/CallApi';
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

const MySidebar = () => {
    const { i18n, t } = useTranslation();
    let { favourite, cart, fetchPersonalData } = useContext(SharedData);
    let location = useLocation()
    console.log(location);

    return (
        <div className='sidebar'>
            <Avatar size={80} src={fetchPersonalData?.image} className='profile_image' />
            <h2>{String(fetchPersonalData?.name)?.charAt(0)?.toUpperCase() + String(fetchPersonalData?.name)?.slice(1)}</h2>
            <List
                className='sidebar_items'
                itemLayout="horizontal"
                dataSource={[
                    {
                        icon: <UserOutlined className="icon" />,
                        text: t('profile'),
                        path: '/account/personal_data',
                        selected: (location?.pathname == '/account' || location?.pathname == '/account/edit' || location?.pathname == '/account/personal_data') ? true : false,
                    },
                    {
                        icon: <Icons.orders className="icon" />,
                        text: 'Orders',
                        count: 12,
                        path: '/account/orders', 
                        selected: location?.pathname == '/account/orders' ? true : false,
                    },
                    // {
                    //     icon: <Icons.save className="icon" />,
                    //     text: 'Save Order',
                    //     path: '/account/cart',  
                    //     count: 8,
                    //     selected: location?.pathname == '/account/cart' ? true : false,

                    // },
                    {
                        icon: <Icons.fav className="icon" />,
                        text: 'Favorite',
                        path: '/account/favorites',   
                        count: favourite?.length,
                        selected: location?.pathname == '/account/favorites' ? true : false,

                    },
                ]}
                renderItem={item => (
                    <Link to={item?.path}>
                        <List.Item className={`sidebar_item ${item.selected ? 'selected' : ''}`}>
                            <List.Item.Meta
                                avatar={item.icon}
                                title={<span className="sidebar_text">{item.text}</span>}
                            />
                            <Badge count={item.count} className='sidebar_badge' />
                        </List.Item>
                    </Link>
                )}
            />
        </div>
    );
};

export default MySidebar
