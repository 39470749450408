import { Player } from '@lottiefiles/react-lottie-player';
import { Button } from 'antd';
import cartEmpty from 'assets/cart.json';
import axios from 'axios';
import Icons from 'constants/Icons';
import { SharedData } from 'context/CallApi';
import cookies from 'js-cookie';
import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import ProductItem from './ProductItem';
import ProductItemSmall from './ProductItemSmall';
import './cart.scss';

const Cards = () => {
  const { fetchHome, fetchCart, fetchFavourite, fetchProductsData, cart } = useContext(SharedData);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const [sizeActive, setSizeActive] = useState(true);
  const navigate = useNavigate();

  let token = localStorage.getItem('token');

  const toggleFavourite = async (item) => {
    if (token) {
      const url = item.in_favourite === 0
        ? `https://holmz.rightclick.com.sa/api/favourite/add`
        : `https://holmz.rightclick.com.sa/api/favourite/${item.product_id}/delete`;

      const method = item.in_favourite === 0 ? 'post' : 'delete';
      if (item.in_favourite === 0) {
        const { data } = await axios[method](url, { product_id: item.product_id }, {
          headers: {
            'Content-Type': 'application/json',
            'Accept-Language': cookies.get('i18next'),
            Authorization: 'Bearer ' + token,
          },
        });

        if (data?.status === 200) {
          toast.success(data?.message);
          fetchFavourite();
          fetchProductsData();
          fetchCart();
        }
      } else {
        const { data } = await axios[method](url, {
          headers: {
            'Content-Type': 'application/json',
            'Accept-Language': cookies.get('i18next'),
            Authorization: 'Bearer ' + token,
          },
        });

        if (data?.status === 200) {
          toast.success(data?.message);
          fetchFavourite();
          fetchProductsData();
          fetchCart();
        }
      }
    } else {
      navigate('/holmes/sign-in')
    }
  };

  const deleteProduct = async (id) => {
    const url = localStorage.getItem('guest_id')
      ? `https://holmz.rightclick.com.sa/api/cart/${id}/delete?guest_id=${localStorage.getItem('guest_id')}`
      : `https://holmz.rightclick.com.sa/api/cart/${id}/delete`;

    const { data } = await axios.delete(url, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': cookies.get('i18next'),
        Authorization: 'Bearer ' + token,
      },
    });

    if (data?.status === 200) {
      fetchCart();
      toast.success(data?.message);
    }
  };

  const deleteAllProduct = async () => {
    const url = localStorage.getItem('guest_id')
      ? `https://holmz.rightclick.com.sa/api/cart/destroy?guest_id=${localStorage.getItem('guest_id')}`
      : `https://holmz.rightclick.com.sa/api/cart/destroy`;

    const { data } = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': cookies.get('i18next'),
        Authorization: 'Bearer ' + token,
      },
    });

    if (data?.status) {
      fetchCart();
      navigate('/');
      toast.success(data?.message);
    }
  };


  const handleCheckout = async () => {
    setLoading(true)
    if (token) {
      const totalAmount = cart.reduce((acc, item) => acc + item.price * item.quantity, 0);
      const items = cart.map((item, index) => ({
        product_id: item.product_id,
        size_id: item.size_id,
        name: item.name,
        quantity: item.quantity,
        amount: item.price * item.quantity,
      }));

      const formData = new FormData();
      formData.append('total_amount', totalAmount * 100);
      items.forEach((item, index) => {
        formData.append(`items[${index}][id]`, item.product_id);
        formData.append(`items[${index}][size_id]`, item.size_id);
        formData.append(`items[${index}][name]`, item.name);
        formData.append(`items[${index}][quantity]`, item.quantity);
        formData.append(`items[${index}][amount]`, item.amount * 100);
        formData.append(`currency`, "SAR");
      });

      const { data } = await axios.post('https://holmz.rightclick.com.sa/api/payment/create-intention', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Accept-Language': cookies.get('i18next'),
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      });

      if (data.status === 200) {
        toast.success('Payment intention created successfully!');
        setLoading(false)

        window.location.href = data?.form_url;
        // navigate('/payment-success');
      } else {
        toast.error('Failed to create payment intention.');
        setLoading(false)
      }
    } else {
      navigate('/holmes/sign-in');
      setLoading(false)
    }
  };
  return (
    <div className='app_cart'>
      <div className="app__slider bg-black">
        <img src={fetchHome.slider_video} className='w-100' alt="" />
      </div>
      <div className="container_cart">
        <header className='app_cart_header flex align-items-center'>
          <div className="Contact-header flex flex-row gap-6 justify-content-center w-100">
            <div className={`app_card bg-transparent`} dir='rtl'>
              <Icons.Card />
              <span>{cart?.length}</span>
            </div>
            <h2 className='text-center'>Cart</h2>
          </div>
          <div className="icons ms-auto cursor-pointer" onClick={deleteAllProduct}>
            <Icons.Close />
          </div>
        </header>
        {cart?.length > 0 ? (
          <div className='bg-transparent app_cart_lg'>
            {cart.map((item, index) => (
              <ProductItem
                key={index}
                item={item}
                sizeActive={sizeActive}
                setSizeActive={setSizeActive}
                toggleFavourite={toggleFavourite}
                deleteProduct={deleteProduct}
              />
            ))}
            <Link to={'/checkout'} className="checkout_btn mt-8 w-100 flex justify-content-center">
              <Button size='large' type='primary'>Checkout Now</Button>
            </Link>
          </div>
        ) : (
          <div className="Player">
            <Player className='w-50' src={cartEmpty} autoplay loop />
          </div>
        )}
        <div className="app__cart_sm">
          {cart.map((item, index) => (
            <ProductItemSmall
              key={index}
              item={item}
              toggleFavourite={toggleFavourite}
              deleteProduct={deleteProduct}
            />
          ))}
          <Link to={'/checkout'} className="checkout_btn mt-8 w-100 flex justify-content-center">
            <Button size='middle' type='primary'>Checkout Now</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Cards;
